.container{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    padding: 20px;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}

.container:hover{
    background-color: var(--green-theme-hover);
}

.container:last-child{
    border-bottom: none !important;
}

.container.profit{
    background-color: rgb(95, 228, 110);
    border-bottom: 1px solid rgba(74, 175, 86, 0.5);
}

.container.loss{
    background-color: rgb(232, 105, 105);
    border-bottom: 1px solid rgba(178, 53, 53, 0.5);
}

.container>div{
    width: 100%;
}

.player_info{
    display: flex;
    flex-direction: row;
}

.player_info > div{
    text-align: left;
    width: 22%;
    padding: 0px 5px;
}

.player_name{
    padding-left: 0px !important;
    width: 34% !important;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
}

.buttons{
    display: flex;
    gap: 20px;
}

.buttons > div{
    background-color: rgb(219, 231, 252);
    padding: 15px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    text-align: center;
}

.buttons > div:active{
    background-color: rgb(198, 210, 231);
}


.popup{
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 50px;
    background-color: rgb(174, 209, 176);
    border-radius: 5px;
    box-shadow: 0px 5px 50px 10px black;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px;
    font-size: 20px;
}

.popup>input{
    font-size: 20px;
    padding: 10px;
}

.popup_button{
    background-color: rgb(219, 231, 252);
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    width: 100px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.popup_button:active{
    background-color: rgb(198, 210, 231);
}

.popup_close_button{
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.popup_close_button:active{
    background-color: rgb(223, 223, 223);
}

.popup .player_info{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 25px;
}


@media screen and (max-width: 600px) {
    .container {
      flex-direction: column;
      gap: 20px;
    }
  }
