.container{
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  padding: 20px;
  border-top: 1px solid #ddd;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}

.container:hover{
  background-color: var(--green-theme-hover);
}

.closed_room{
  background-color: rgb(215, 215, 215);
}

.lock{
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: none;
}