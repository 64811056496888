
.container{
    width: 100%;
    max-width: 800px;
    height: auto;
    background: rgba(255,255,255,1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    border-radius: 5px;
}

.container *{
    text-align: center;
    margin: 0;
    width: 100%;
}

.container h2{
    font-size: 24px;
}

.container input{
    border: none;
    font-size: 20px;
    padding: 10px;
    border: 2px var(--green-theme) solid;
    border-radius: 5px;
    transition: border 0.2s ease-in-out;
}

.container input:focus{
    border: 2px var(--green-theme-focus) solid;
    outline: none;
}

.container button{
    border: none;
    background-color: var(--green-theme);
    color: white;
    border-radius: 5px;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
}

.container button:active{
    background-color: var(--green-theme-active);
}
