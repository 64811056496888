.container{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.container h2{
    font-size: 24px;
}

.reimbursment{
    padding: 20px 10px;
    width: 100%;
    text-align: center;
    border-top: 1px solid #ddd;
}

.reimbursment:nth-child(2n){
    background-color: rgba(196, 208, 236, 0.409);
}

.not_zero{
    padding: 20px;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.not_zero p{
    margin: 0;
    text-align: center;
}