
.room_date{
    box-shadow: none !important;
    text-align: center;
    font-size: clamp(18px, 5vw, 30px); 
}

.intput_container{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.closed_room{
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.closed_room h2{
    font-size: 24px;
    margin: 0;
}

.closed_room p{
    margin: 0;
    text-align: center;
}

@media screen and (max-width: 600px) {

    .intput_container{
        flex-direction: column;
    }
  }

  @media screen and (max-width: 400px) {

    .intput_container{
        flex-direction: column;
    }
  }


