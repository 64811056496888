.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.header{
    display: flex;
    background-color: #ccc;
    padding: 20px;
}

.header>div{
    width: 22%;
    font-size: 20px;
}

.player_name{
    width: 34% !important;
}

@media screen and (max-width: 600px) {
    .header>div{
        font-size: 16px;
    }
  }
