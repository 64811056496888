
.main{
    margin-top: 80px;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.main>*{
    max-width: 800px;
    box-shadow: 0px 5px 10px 2px rgba(0,0,0,0.1);
}

@media screen and (max-width: 600px) {
    .main {
        margin-top: 60px;
    }
}