.header{
    width: 100%;
    height: 80px;
    background-color: var(--green-theme);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.5);
    transition: all 0.2s ease-in-out;
    z-index: 10;
}

.header.top{
    box-shadow: none;
}

.header a{
    text-decoration: none;
}

.header h1{
    color: white;
    text-align: center;
    font-family: PokerFont;
    margin: 0;
}

.header h1 img{
    width: 20px;
    margin: 0px 5px;
}

.header h1 img.spade{
    transform: scale(1.4);
}

.header .hamburger_icon{
    position: absolute;
    height: 40px;
    cursor: pointer;
    right: 20px;
    filter: invert(100%);
}

.controls{
    position: fixed;
    top: 80px;
    right: 10px;
    width: 60px;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    padding-bottom: 20px;
    gap: 15px;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    box-shadow: 0px 5px 15px 1px rgba(0,0,0,0.5);
    backdrop-filter: blur(10px);
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
}

.controls.active{
    transform: translateY(0);
    opacity: 1;
    pointer-events: initial;
}

.controls .save_icon{
    width: 100%;
    cursor: pointer;
    filter:  brightness(0) invert(100%);
    opacity: 0;
    transform: translateY(-5px);
    transition: all 0.2s ease-in-out;
}

.controls .history_icon{
    width: 100%;
    height: auto;
    cursor: pointer;
    filter: invert(100%);
    opacity: 0;
    transform: translateY(-5px);
    transition: all 0.2s ease-in-out;
}

.controls.active .history_icon{
    transition-delay: 0.2s;
    opacity: 1;
    transform: translateY(0);
}

.controls.active .save_icon{
    transition-delay: 0.3s;
    opacity: 1;
    transform: translateY(0);
}






@media screen and (max-width: 600px) {
    .header {
       height: 60px;
    }
    .controls {
        top: 60px;
    }
}
